import React from "react"
import styled from 'styled-components';
import Layout from "../layouts";
import Hover from "../components/hover";
import Footer from "../components/footer";
import VideoMockup from "../components/videoMockup";
import Container from "../components/./container";
import BackgroundImage from "../components/image";
import AnimatedText from '../components/animatedText';
import ArrowDown from "../components/arrowDown";
import AnimatedImage from "../components/animatedImage";
import Seo from "../components/seo"

const Wrapper = styled.div`
.flux > div {
    padding-top: 100px;
}

.flux > div:first-child {
    padding-top: 20vh;
    padding-bottom: 5vh;
}

.flux > div:nth-child(2){
    padding-top: 0;
}

h1{
    text-align:left;
    text-transform: uppercase;
    letter-spacing: .5rem;
    font-size: 5rem;
    padding-bottom:2rem;
}

.wecreate2,
.wecreate3{
    position:absolute;
    top:0;
}

@media(max-width:512px){
    h1{
    letter-spacing: .3rem;
    font-size: 10vw;
}
}
`


// markup
const VirtualFashion = () => {

    return (
        <>
            <Layout>
                <Seo title="Virtual Fashion - Vertual" />
                <Hover type="">
                    <main data-scroll data-scroll-id="update">
                        <ArrowDown />
                        <Wrapper>
                            <div className="flux">
                                <Container block position="intro" reversed direction="column">
                                    <AnimatedText tag="h1">Virtual Fashion</AnimatedText>
                                    <AnimatedText tag="p">
                                        We create high-quality, photorealistic digital items of any kind of apparel, footwear, and accessories that can be purchased as
                                        NFTs and used for mixed reality applications like AR filters and gaming platforms.
                                    </AnimatedText>
                                </Container>
                                <VideoMockup video={"virtualfashion"} fullpage />
                                <Container vh minheight>
                                    <div className="mockupVideo">
                                        <AnimatedImage><BackgroundImage filename={"virtualfashion_bag.jpg"} /></AnimatedImage>
                                    </div>
                                    <div className="description">
                                        <AnimatedText tag="h2">With digital fashion the possibilities are endless</AnimatedText>
                                        {/* <AnimatedText tag="p">making a memorable attendee experience by
                                                integrating manifestation of actual reality, but one based in a virtual world, optimized for every device,
                                                every language, and timezone.</AnimatedText> */}
                                    </div>
                                </Container>
                                <VideoMockup video={"virtualfashion2"} fullpage />
                                <Footer page="phygitalexperiences" />
                            </div>
                        </Wrapper>
                    </main>
                </Hover>
            </Layout>
        </>
    )
}

export default VirtualFashion


